import React, { useState } from "react";
import {Button} from "react-bootstrap";
import "./PlantQASurvey.css";
import {SpeechToText} from "../../commom/SpeechToText";
import introJs from 'intro.js'; // Import intro.js
import 'intro.js/introjs.css'; // Import intro.js styles
import './introjs.css'
import {InfoCircleFilled} from '@ant-design/icons' 
const SurveyDetailForm = (props) => {
    const [selectedProduct, setSelectedProduct] = useState('null'); // State to store selected dropdown value
     
      
    const startIntro =async (elementId,startFromStep=1) => {
        console.log(props.image)
        const intro = introJs();
    await    intro.setOptions({
            steps: [
                {
                    element: `#${elementId}`,
                    intro:  `<img src="./images/${props.image} Holes.jpg" alt="${selectedProduct}" style="width: 100%; height: auto; margin-top: 1px;" />`,
                    showStepNumbers: false,
                    showBullets: false,
                    showButtons: true, // Ensure buttons like the close button are enabled
                    exitOnOverlayClick: true, // Allow users to close by clicking outside
                    exitOnEsc: true, // Allow users to close using Escape key
                },
                {
                    element: `#${elementId}`,
                    intro:  `<img src="./images/${props.image} stains.jpg" alt="${selectedProduct}" style="width: 100%; height: auto; margin-top: 10px;" />`,
                    showButtons: true, 
                },
                {
                    element: `#${elementId}`,
                    intro:  `<img src="./images/${props.image} Poor Finish.jpg" alt="${selectedProduct}"style="width: 100%; height: auto; margin-top: 1px;" />`,
                    showButtons: true, 
                }
            ]
        }).goToStepNumber(startFromStep).then(r => r.start());
    };

    return (
        <form onSubmit={props.submitHandler} className="formContainer formBorder w-100 p-3 mx-auto">
            <div className="row">
                {props.config && props.config.length && props.config.map((input, keyIndex) => {
                    const {id, type, required, fieldName, title, answers, value} = input;
                    //   console.log('from form',input)
                    return (
                        <div className="form-group col-6" key={id + keyIndex}>

                {(type !== "Text" && type !== "Checkbox" ) &&
                <label htmlFor={fieldName} className="col-form-label" name={fieldName} data-hint="Click here for more info">
                    {required && <span className="text-danger starCss">*</span>}
                    {title}
                </label>
                }

            {(title === "Holes" && props.image !== 'select' && props.image !== "") && (
                <label htmlFor={fieldName} className="col-form-label" name={fieldName}>
             &nbsp;  <InfoCircleFilled onClick={() => startIntro(`holes-info-${keyIndex}`, 1)} />
                </label>
            )}

            {(title === "Stains" && props.image !== 'select' && props.image !== "") && (
                <label htmlFor={fieldName} className="col-form-label" name={fieldName}>
        &nbsp;   <InfoCircleFilled onClick={() => startIntro(`holes-info-${keyIndex}`, 2)} /> 
                </label>
            )}

            {(title === "Poor Finish" && props.image !== 'select' && props.image !== "") && (
                <label htmlFor={fieldName} className="col-form-label" name={fieldName}>
           &nbsp;   <InfoCircleFilled onClick={() => startIntro(`holes-info-${keyIndex}`, 3)} />
                </label>
            )}
                            {(type === "Checkbox") &&
                            <label htmlFor={fieldName} className="col-form-label" name={fieldName}
                                   onClick={props.inputLabelClickHandler}>
                                {required && <span className="text-danger starCss">*</span>}
                                {title}
                            </label>
                            }
                            {(type === "Text") &&
                                <SpeechToText fieldName={title} handleChange={props.handleChange} name={fieldName}
                                              showMessage={props.showMessage} resetComment={props.resetComment}
                                              required={required} inputValidation={props.inputValidation[fieldName]}/>
                            }
                            <div>
                                {((type === "Date" || type === "Number") && required) &&
                                <input onChange={props.handleChange} name={fieldName} type={type}
                                       className={`form-control ${(props.inputValidation[fieldName]) ? "" : "errorDiv"}`}
                                       value={value}/>
                                }
                                {((type === "Date" || type === "Number") && !required) &&
                                <input onChange={props.handleChange} name={fieldName} type={type}
                                       className="form-control"
                                       value={value}
                                />
                                }

                                {(type === "Dropdown" && required) &&
                                <select value={value} onChange={props.handleChange} name={fieldName}
                                        className={`form-select ${props.inputValidation[fieldName] ? "" : "errorDiv"}`}>
                                    <option value="select">Select</option>
                                    {
                                        answers && answers.length
                                        && answers.map((obj, index) => {
                                            return <option key={index + obj.value}
                                                           value={obj.value}>{obj.value}</option>
                                        })
                                    }
                                </select>
                                }

                                {(type === "Dropdown" && !required) &&
                                <select value={value} onChange={ props.handleChange } name={fieldName}
                                        className="form-select">
                                    <option value="select">Select</option>
                                    {
                                        answers && answers.length
                                        && answers.map((obj, index) => {
                                            return <option key={index + obj.value}
                                                           value={obj.value}>{obj.value}</option>
                                        })
                                    }
                                </select>
                                }

                                {type === "Checkbox" &&
                                <input className="form-check-input" onChange={props.inputLabelClickHandler} name={fieldName}
                                       type={type} checked={value}/>}
                            </div>
                        </div>
                    )
                })}
            </div>

            < div
                className="row justify-content-center m-1">
                < Button
                    type="submit"
                    className="formButton mx-sm-1 buttonCss"> Submit </Button>
                    <Button onClick={props.cancelHandler} className="formButton buttonCss">Reset</Button>
                    </div>
        </form>
)
};

export default SurveyDetailForm;