import React, {useContext, useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {useDispatch, useSelector} from "react-redux";
import axios from "../../apis/AxiosInstance";
import {
    getAllArrayValue,
    getArrayValue, getSelectedAllFlag, getUserAccessToUrl, searchArrayWithActive,
    specificArrayData,
    surveyFieldArray, updateMultiSelectSearch
} from "../../commom/CommonFunction";
import {getDateInUTC} from "../../commom/DateFormat";
import useAxiosFunction from "../../hook/AxiosHook";
import Loader from "../../commom/LoaderComponent";
import {setDateRange} from "../../reducer/DateRangeReducer";
import {userDetail} from "../../reducer/UserReducer";
import {setSite} from "../../reducer/SiteReducer";
import {setAllSite} from "../../reducer/SiteAllReducer";
import {setPlant} from "../../reducer/PlantReducer";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {setUserRole} from "../../reducer/UserRoleReducer";
import {ListGroup} from "react-bootstrap";
import {handleInputChange} from "../../commom/FormHandler";
import SurveyReportForm from "./SurveyReportForm";
import {
    getClientStatReport, getCommonTableData,
    getLinenQualityReport, getOnSiteReport, getOnSiteTableData,
    getSurveyReportBody, getSurveyReportHeader, getSurveyReportHeaderFromQuestions,
    surveyReportData
} from "../../commom/CommonReportFunction";
import ReportTableModal from "../reports/ReportTableModal";
import {exportSurveyData} from "../../commom/ExportToExcel";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {useOuterClick} from "../../hook/VisibilityHook";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import {logIn, logOut} from "../../reducer/LoginReducer";

const SurveyReport = () => {
    const startDate = useSelector((state) => state.dateRangeReducer.startDate);
    const endDate = useSelector((state) => state.dateRangeReducer.endDate);

    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const dispatch = useDispatch();

    const [siteList, setSiteList] = useState("");
    const [sitesList, setSitesList] = useState("");
    const [siteData, setSiteData] = useState("");

    const [surveyList, setSurveyList] = useState("");

    const [plantData, setPlantData] = useState("");
    const [plantList, setPlantList] = useState();

    const [formValue, setFormValue] = useState({
        survey: "",
        showPlant: false,
        selectAllPlant: false,
        plantCount: 0,
        showSite: false,
        selectAllSite: false,
        siteCount: 0,
        sam: ""
    });
    const [config, setConfig] = useState();
    const [rowHeader, setRowHeader] = useState("");
    const [tableData, setTableData] = useState("");
    const [reportHeader, setReportHeader] = useState(null);
    const [showReportTable, setShowReportTable] = useState(false);
    const [showDropdownInReports, setShowDropdownInReports] = useState(false);
    const [isDropdownChanged, setDropdownChanged] = useState(false);
    const [reportData, setReportData] = useState("");
    const [selectAllField, setSelectAllField] = useState(true);
    const [sites,setSites] = useState();
    const userState = useSelector((state) => state.userState.user.email);


    const innerRef = useOuterClick(e => {
        setFormValue({...formValue, showPlant: false});
    });
    const innerRefSite = useOuterClick(e => {
        setFormValue({...formValue, showSite: false});
    });
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(showDateInHeader(true));
        dispatch(setDateRange());

        getUserDetailWithMasterData().then((response) => {
            let isAuthorisedUrl = getUserAccessToUrl(window.location.pathname, response.securityRoles.modules);
            let isAuthorised = !!response._doc.authorizationTokens;
            if(!isAuthorised || !isAuthorisedUrl) {
                handleLogoutFunction();
            }

            let isProductionEnv = (response.prodEnv === "true");
            dispatch(logIn({"token": response._doc.authorizationTokens, "isProductionEnv": isProductionEnv}));
            setSitesList(response.sites);
            setSiteData(response.sites);

            setPlantList(response.plants);
            setPlantData(response.plants);

            dispatch(userDetail({"user": response._doc}));
            dispatch(setUserRole(response.securityRoles));
            dispatch(setSite(response.sites));
            dispatch(setAllSite(response.sites));
            dispatch(setPlant(response.plants));
            setSurveyList(response.surveys);
            setPlantList(updateMultiSelectOption(response.plants, "plant"));
        });
        getallSites().then((data)=>{
            setSites(data)
        });

    }, []);

    const handleLogoutFunction = ()=> {
        auth.logout();
        dispatch(logOut());
        navigate('/login')
    };

    const getallSites = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/getAllSiteByHc',
        });
        return await response.data;
    }
    const getUserDetailWithMasterData = async () => {
        let user = JSON.parse(localStorage.getItem('userDetail'));
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getUserDetail',
            requestConfig: {
                "data" : {
                    "id": user.userId
                }
            }
        });
        return await response.data;
    };

    const updateListSelection = (event) => {
        let eventObject = {
            "currentTarget" : {
                "value": event.currentTarget.innerText,
                "name": event.currentTarget.getAttribute("name"),
            }
        };
        handleChange(eventObject)
    };

    const updateMultiSelectOption = (fetchedValue, label) => {
        return fetchedValue.map((obj) => {
            let data;

            if (label === "plant") {
                data = obj._id;
            } else {
                data = obj.value;
            }

            return {
                label: data,
                value: false
            };
        });
    };

    const getSites = (plantName) => {
        let sites = [];

        if (plantName && plantName.length) {
            siteData.forEach(obj => {
                let plantObj = Object.assign({}, obj);
                if (plantName.indexOf(plantObj.plant) !== -1) {
                    sites.push({
                        label: plantObj.site,
                        value: false
                    });
                }
            });
        }

        return sites;
    };

    const handleCheckboxChange = (event, newData, checked, count, name) => {
        if (name === "plant") {
            setPlantList(newData);
            let plantNames = getArrayValue(newData);
            let data = getSites(plantNames);
            setSiteList(data);
            setSitesList(data);
            setFormValue({
                ...formValue,
                selectAllPlant: checked,
                plantCount: count,
                selectAllSite: false,
                siteCount: 0
            });
        } else if (name === "site") {
            setSiteList(newData);
            setFormValue({...formValue, selectAllSite: checked, siteCount: count});
        }
    };

    const handleClick = (event) => {
        const name = event.currentTarget.getAttribute("name");

        if (name === "plant") {
            let flag = formValue.showPlant;

            if(!flag) {
                let data = updateMultiSelectSearch(plantData, "_id", "", plantList);
                setPlantList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showPlant: !flag, selectAllPlant: selectedAll});
            } else {
                setFormValue({...formValue, showPlant: !flag});
            }
        } else if (name === "site") {
            let flag = formValue.showSite;

            if(!flag) {
                let data = updateMultiSelectSearch(sitesList, "label", "", siteList);
                setSiteList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showSite: !flag, selectAllSite: selectedAll});
            } else {
                setFormValue({...formValue, showSite: !flag});
            }
        }
    };

    const handleSearch = (event) => {
        const {value, name} = event.currentTarget;

        if (name === "plant") {
            let data = updateMultiSelectSearch(plantData, "_id", value, plantList);
            setPlantList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllPlant: selectedAll});
        } else if (name === "site") {
            let data = updateMultiSelectSearch(sitesList, "label", value, siteList);
            setSiteList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllSite: selectedAll});
        }
    };

    const handleChange = (event) => {
        const currentFormState = handleInputChange(event, formValue);
        setFormValue(currentFormState);

        if (event.currentTarget.name === "survey") {
            getFormData(event.currentTarget.value);
            setSelectAllField(true);
        }
    };

    const getFormData = (title) => {
        async function getSurveyByTitle() {
            const response = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getSurveyByTitle',
                requestConfig: {
                    data: {"title": title}
                }
            });

            return await response.data;
        }

        getSurveyByTitle().then((fetchedValue) => {
            const surveyFieldArrayData = surveyFieldArray(fetchedValue);
            setConfig(surveyFieldArrayData);
        });
    };

    const handleConfigCheckboxChange = (event) => {
        let value = event.currentTarget.attributes['data-label'].value;

        const newData = config.map(obj => {
            let data = Object.assign({}, obj);

            if (data.title === value) {
                let checked = !data.value;
                data.value = checked;
            }

            return data;
        });

        setConfig(newData);
    };

    const selectAllFieldOfSurvey = () => {
        let newState = !selectAllField;
        setSelectAllField(newState);

        if(newState) {
            const newData = config.map(obj => {
                let data = Object.assign({}, obj);
                data.value = true;
                return data;
            });

            setConfig(newData);
        } else {
            const newData = config.map(obj => {
                let data = Object.assign({}, obj);
                data.value = false;
                return data;
            });

            setConfig(newData);
        }
    };

    const getDataForSelectedSurvey = async () => {
        let surveys = [formValue.survey];
        let selectedSiteDate = getArrayValue(siteList);
        let sites;

        if (selectedSiteDate) {
            sites = selectedSiteDate;
        } else {
            sites = getAllArrayValue(siteList);

            if (!sites) {
                sites = specificArrayData(siteData, "site");
            }
        }

        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getReportForLatestSurvey',
            requestConfig: {
                data: {
                    "sites": sites,
                    "survey": surveys,
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(endDate),
                }
            }
        });

        return await response.data;
    };

    const showSurveyData = () => {
        getDataForSelectedSurvey().then((response) => {
            let survey = searchArrayWithActive(formValue.survey, surveyList, "title", "active");
            let value = formValue.survey;
            let reportData;
            if (value === "Onsite Linen Inspection") {
                reportData = getOnSiteReport(response, siteData, value, survey);
            }  else {
                reportData = getLinenQualityReport(response, siteData, value, survey);
            }

            let tableArray;
            if (value === "Onsite Linen Inspection") {
                tableArray = getOnSiteTableData(response, siteData, value, survey);
            }  else {
                tableArray = getCommonTableData(response, siteData, value, survey);
            }

            let rowHeader = getSurveyReportHeaderFromQuestions(survey);
            let reportHeaderValue = formValue.survey + " Report";
            setRowHeader(rowHeader);
            setTableData(tableArray);
            setReportData(reportData);
            setShowReportTable(true);
            setReportHeader(reportHeaderValue);
        })
    };

    const handleClose = () => {
        setShowReportTable(false);
        setShowDropdownInReports(false);
        setDropdownChanged(false);

    };
    const handleEdit = async () => {
        console.log('Clicked Eidt')
        setShowDropdownInReports(true);
        if (isDropdownChanged) {
            // Handle the update logic

            // After update, reset dropdown state
            await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/updateSiteByDropdown',
                requestConfig: {
                    data:isDropdownChanged
                }
            }).then((res)=>{
                showSurveyData()
            });
            setDropdownChanged(false);
            setShowDropdownInReports(false);
        } else {
            // Handle the edit logic
            console.log("Editing data...");
        }
    };
    const handleDropdownChange = (event) => {
        if (event.target.value) { // Ensure value is not empty
            const selectedValue = JSON.parse(event.target.value); // Parse 
        setDropdownChanged(selectedValue);

        }
    };
    const exportSurveyDataArray = () => {
        getDataForSelectedSurvey().then((response) => {
            let rowHeader = [
                {
                    name: "Plant",
                    header: "Plant",
                    alignment: "left"
                },
                {
                    name: "Site",
                    header: "Site",
                    alignment: "left"
                },
                {
                    name: "Date",
                    header: "Date",
                    alignment: "left"
                },
            ];

            let configTitle = [];
            if(config && config.length > 0) {
                config.forEach((configObj) => {
                    if (configObj.value) {
                        rowHeader.push({
                            name: configObj.title,
                            header: configObj.title,
                            alignment: "left"
                        });
                        configTitle.push(configObj.fieldName);
                    }
                });
            }

            let reportData = surveyReportData(response, configTitle);
            let reportHeaderData =  "surveyData";

            let data = {[reportHeaderData]: reportData};
            let reportHeaderValue = "SurveyData" + ".xlsx";
            exportSurveyData(data, "surveyData.xlsx");
        })
    };

    const exportReportData = () => {
        let reportHeaderData = "surveyData";

        let data = {
            [reportHeaderData]: reportData
        };

        let reportHeaderValue = "SurveyData" + ".xlsx";

        exportSurveyData(data, "surveyData.xlsx");
    };
//  console.log("date fro plantHc",sites)
    return (
        <Container fluid="md">
            <Loader isLoading={loading}/>
            <Link to="/reports"><Button className="buttonCss">Back To Reports and Export</Button></Link>
            <div className="form-group col">
                <label htmlFor="survey" className="col-form-label">Select Survey to generate report : </label>
                <div>
                    <ListGroup className={`${!formValue.survey ? "divReportListCSS": "divListCSS" }`}>
                        {
                            surveyList && surveyList.length
                            && surveyList.map((obj, index) => {
                                return <ListGroup.Item name="survey" key={obj.title + index} onClick={updateListSelection}
                                                       className={`listClass ${(formValue.survey === obj.title) ? "active": "" }`}
                                >{obj.title}</ListGroup.Item>
                            })
                        }
                    </ListGroup>
                </div>
            </div>
            {formValue.survey &&
            <SurveyReportForm config={config} handleConfigCheckboxChange={handleConfigCheckboxChange}
                              showSurveyData={showSurveyData}
                              exportSurveyDataArray={exportSurveyDataArray} selectAllFieldOfSurvey={selectAllFieldOfSurvey}
                              formValue={formValue} selectAllField={selectAllField}
                              plantList={plantList} siteList={siteList}
                              handleCheckboxChange={handleCheckboxChange} handleClick={handleClick}
                              handleSearch={handleSearch}
                              innerRef={innerRef} innerRefSite={innerRefSite}
                              response={response} error={error}/>
            }
            <Row className="align-items-center mb-sm-1">
                <ReportTableModal rowHeader={rowHeader} tableData={tableData} currentUser ={userState} showReportTable={showReportTable} siteEdit={sites}
                                  handleClose={handleClose} showDropodowns ={showDropdownInReports} handleEdit={handleEdit} handleDropdown = {handleDropdownChange}  isDropdownChanged={isDropdownChanged} header={reportHeader} exportReportData={exportReportData}/>
            </Row>
        </Container>
    )
};

export default SurveyReport;